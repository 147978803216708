$(document).ready(function(){
	$(window).load(function(){
		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		headerPushTop();
		storeLocator();
		recipeGallery();
		hpGallery();
    active_state();
		
		$('.hp-main.main-panel').css({
			'min-height': $pageHeight,
			'margin-bottom' : $quickLinks,
			//'margin-top': $navHeight,
			'padding-bottom': $footerHeight
		});
		$('.inner-main.main-panel').css({
			'min-height': $pageHeight,
			'margin-bottom' : $quickLinks,
			'margin-top': $navHeight,
			'padding-bottom': $footerHeight
		});

		$('.back-to-top').hide();
		
		$('.back-to-top').css({
			'bottom' : $quickLinks + 35
		});

		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

		$('.loader-overlay').fadeOut(200);

		$('.mailto-container .mailto-contain, .career-container .career-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		// Sub Menus
		$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function(){
			$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
		});
		$('.menu-container .menu-contain nav ul li .sub-menu').each(function(){
			$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
		});

	});
	$('.single-item').slick();

function active_state() {

  var $current_page = $('#current-page').val();

  if($current_page && $current_page == 'recipe') {

    $('.recipe-menu').addClass('current-menu-item');
    console.log('test');

  } else if( $current_page && $current_page == 'careers' ) {

    $('.career-menu').addClass('current-menu-item');

  }

}

});

function headerPushTop(){

}


function storeLocator(){
	if( $('#store-initialize').length > 0 ) {
		var $store = [];
		$.ajax({
			url : $('.ajaxlocation').data('ajaxlocation'),
			type : 'POST',
			dataType: 'json',
			data : {
				action : 'request_stores',
			},
			success: function(response) {
				$store = response.stores;
			}
		});
		setTimeout(function(){
			$(document).on('click', '.store-item', function(){
				var id = $(this).data('store-id');
				$.each($store, function(k, v) {
				  if( k == id ) {
				    $('#store-locate').html(v);
				  }
				});
			});
		}, 300);
	}
}

function recipeGallery(){
	$('.recipe-gallery-image').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  fade: true,
	  swipe: false,
	  asNavFor: '.recipe-gallery-nav'
	});
	$('.recipe-gallery-nav').slick({
		arrows: true,
	  slidesToShow: 4,
	  slidesToScroll: 1,
	  asNavFor: '.recipe-gallery-image',
	  dots: false,
	  centerMode: false,
	  focusOnSelect: true,
		prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
	});
}

function hpGallery(){
	$('.gal-slider').slick({
		dots: false,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    verticalSwiping: true,
    arrows: true,
    prevArrow : '<button type="button" class="slick-prev slick-controller fas fa-long-arrow-alt-up"></button>',
		nextArrow : '<button type="button" class="slick-next slick-controller fas fa-long-arrow-alt-down"></button>',
	});
}

